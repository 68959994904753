<template>
  <div class="evaluateContent">
    <div class="header">
      <i class="el-icon-alifanhui" @click="back"></i>
      <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden">{{learningProjectName}}</div>
      <i></i>
    </div>
    <div class="boxMain">
      <!-- <div class="mainHeader">
                    <div class="headerLeft" style=" font-weight:bold">{{learningProjectName}}</div>
                    <div class="headerRight">
                      <div>
                        <span>{{studentName}}</span>
                      </div>
                    </div>
                  </div> -->
      <div class="mainVideo">
        <video :src="VideoPath" controls="controls" id="video" webkit-playsinline="true" playsinline="true" style="height:90%">
          your browser does not support the video tag
        </video>
      </div>
      <div class="mainContent">
        <div class="headerLeft" style=" font-weight:bold">{{title}}</div>
        <div class="headerRight">
          <div>
            <span>{{studentName}} </span>
            <img src="../../../assets/img/dianping.png" class="dianping" @click="dpshow" />
          </div>
        </div>
        <ul class="sortList">
          <li v-for="(item,index) in dataList" :key="index">
            <span style="color:#ccc;font-weight:bold">{{item.Item1}}</span>
            <div class="quesMain">
              <p>{{item.Title}}</p>
              <div class="scoreBox">
                <van-slider v-model="item.Score" :min="item.MinScore" :max="item.MaxScore" :step="item.MaxScore/5" @change="numChange" />
                <van-stepper v-model="item.Score" :min="item.MinScore" :max="item.MaxScore" :step="item.MaxScore/5" @change="numChange" />
              </div>
            </div>
          </li>
          <li>
            <span style="color:#ccc;font-weight:bold">总结</span>
            <van-field v-model="message" type="textarea" placeholder="请输入评语" rows="4" autosize />
          </li>
        </ul>
        <div class="completeBtn" @click="complete">完成评价</div>
      </div>
    </div>

    <!-- 弹出框内容 -->
    <van-dialog v-model="show" :title="title" show-cancel-button confirmButtonText="提交" @confirm="dialogConfirm" @cancel="dialogCancel">
      <div class="dialogMain">
        <p>最终成绩： <span style="font-size:16px;font-weight:bold;color:#74D798">{{currScore}}/{{allScroe}}</span></p>
        <p>操作用时：{{getHMS(time)}}</p>
      </div>
    </van-dialog>

    <!-- 弹出框内容 -->
    <van-dialog v-model="audioshow" title="语音点评" confirmButtonText="关闭" @confirm="audioConfirm">
      <div class="dialogMain">
        <div style="height:300px;overflow: hidden;overflow-y:auto;">
          <el-table :data="auidoList" border style="width: 98%;margin-left:1%;margin-bottom: 60px;" :header-cell-style="{'background':'#E8E8E8','font-weight':'bold','color':'black'}">
            <el-table-column align="center" prop="videotime" label="录音视频截点" width="120">
            </el-table-column>
            <el-table-column align="center" prop="audiotime" label="录音时长">
            </el-table-column>
            <el-table-column align="center">
              <template slot-scope="scope">
                <img src="../../../assets/img/open.png" class="operate" @click="openaudio(scope.row.path)" />
                <img src="../../../assets/img/delete.png" class="operate" @click="deleteaudio(scope.$index)" />
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="btndiv">
          <button :class="btnclass" @click="soundbtn">{{btntext}}</button>
        </div>
      </div>
    </van-dialog>
    <audio id="audio" :src="audiosrc" style="display:none;" />
  </div>
</template>

<script>
import { Notify } from 'vant';
import Cookies from 'js-cookie';
import Recorder from 'recorder-core'
import 'recorder-core/src/engine/wav'
// let recorder = new Recorder();
let rec = Recorder({ type: "wav", sampleRate: 16000, bitRate: 16 });

export default {
  data() {
    return {
      dataList: [],
      message: "", // 总结
      show: false,
      audioshow: false,
      type: "", // 表类型
      evaluateID: "",
      title: "", // 表名
      allScroe: 0, // 总分
      currScore: 0, // 当前得分
      jobTaskLogID: "",//作业记录ID
      teacherID: "",
      studentName: "",
      learningProjectName: "",
      RecordID: "",
      VideoPath: "",
      time: 0,
      timer: null,
      auidoList: [],
      audiosrc: "",
      btnclass: "start",
      btntext: "开始录音",
      duration: 0,
    }
  },
  methods: {
    // 获取数据
    initData() {
      this.$axios.get('/api/JobTask/TeacherEvaluateLog?jobTaskLogID=' + this.jobTaskLogID, "").then(res => {
        var data = JSON.parse(res.data);
        console.log(data);
        if (data.code == 200) {
          this.title = data.data.EvaluateTitle;
          this.evaluateID = data.data.EvaluateID;
          this.dataList = data.data.EvaluateItemsList;
          this.VideoPath = data.data.VideoPath;
          this.RecordID = data.data.ID;
          this.message = data.data.Remark;

          for (var i = 0; i < data.data.CommentsList.length; i++) {
            let audioObj = {
              videotime: data.data.CommentsList[i].Remark.split('|')[0],
              audiotime: data.data.CommentsList[i].Remark.split('|')[1],
              path: data.data.CommentsList[i].Message,
            }
            this.auidoList.push(audioObj);
          }

          this.dataList.forEach(v => {
            this.allScroe += v.MaxScore;
            this.currScore += v.Score;
          });
        }
      });
    },
    // 点击头部左上角返回
    back() {
      this.$router.push({ name: "teacherworklist" });
    },
    // 点击步进器加减按钮
    numChange() {
      this.currScore = 0;
      this.dataList.forEach(v => {
        this.currScore += v.Score;
      });
    },
    // 点击完成按钮
    complete() {
      this.show = true;
      clearInterval(this.timer);
    },
    // 点击点评按钮
    dpshow() {
      this.audioshow = true;
    },
    soundbtn() {
      if (this.btnclass != "stop") {

        this.startRecord();
      }
      else {
        this.endRecord();
      }
    },
    // 开始录音
    startRecord() {
      this.btnclass = "stop";
      this.btntext = "停止录音";
      this.btnclick = "endRecord";

      rec.start();
    },
    // 结束录音
    endRecord() {
      this.btnclass = "start";
      this.btntext = "开始录音";
      this.btnclick = "startRecord";


      rec.stop((blob, duration) => {
        console.log("时长:" + duration + "ms");
        this.duration = duration / 1000;

        let formData = new FormData()
        formData.append('file', blob)
        this.$axios.post("/api/JobTask/AudioUpload", formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
          var data = JSON.parse(res.data);
          if (data.code == 200) {
            //当前视频时间
            var _video = document.getElementById("video");
            var _cTimes = parseInt(_video.currentTime) % 60;
            var cTimes = "";

            if (_cTimes <= 0) {
              cTimes = "00";
            }
            else {
              cTimes = _video.toString().length <= 1 ? "0" + _cTimes : _cTimes;
            }

            //录音时间
            var _aTimes = parseInt(this.duration) % 60;
            var aTimes = "";

            if (_aTimes <= 0) {
              aTimes = "00";
            }
            else {
              aTimes = _aTimes.toString().length <= 1 ? "0" + _aTimes : _aTimes;
            }

            let audioObj = {
              videotime: (parseInt(_video.currentTime / 60) <= 0 ? 0 : parseInt(_video.currentTime / 60)) + ":" + cTimes,
              audiotime: (parseInt(this.duration / 60) <= 0 ? 0 : parseInt(this.duration / 60)) + ":" + aTimes,
              path: data.data,
            }
            this.auidoList.push(audioObj);

            console.log(data);
            console.log('上传录音文件成功！')
          } else {
            console.log('上传录音文件失败！')
          }
        })

      })

      // recorder.stop();
      // console.log('结束录音')
      // this.upload();
    },
    // 点击播放按钮
    openaudio(src) {
      var _audio = document.getElementById("audio");
      _audio.pause();

      this.audiosrc = src;
      setTimeout(() => {
        _audio.play();
      }, 1000);

      console.log("播放音频" + src)
    },
    // 点击删除按钮
    deleteaudio(index) {
      this.auidoList.splice(index, 1);
      console.log("删除音频" + index);
    },
    //关闭点评弹框
    audioConfirm() {
      var _audio = document.getElementById("audio");
      _audio.pause();
    },
    // 点击弹框确认按钮
    dialogConfirm() {
      let newArr = [];
      this.dataList.forEach(v => {
        let newObj = {

          EvaluationItemID: v.ID,
          Answer: v.Score,
          MaxScore: v.MaxScore,
        }
        newArr.push(newObj);
      });

      let para = {
        ID: this.RecordID,
        EvaluateID: this.evaluateID,
        UserID: this.teacherID,
        EvaluateTitle: this.title,
        Remark: this.message,
        CompletionTime: this.time,
        StudentGroupID: '',
        JobTaskLogID: this.jobTaskLogID,
        EvaluateItemsRequestList: newArr,
        CommentsList: this.auidoList,
        UserType: "homework",
      };
      console.log(para);
      this.$axios.post('/api/JobTask/UpdateEvaluateRecord', para).then(res => {
        var data = JSON.parse(res.data);
        console.log(data);
        if (data.code) {
          Notify({
            message: '提交成功', duration: 1000,
            background: '#2ACC9B'
          });
          this.$router.push({ name: "teacherworklist" });
        }
      });

    },
    // 点击弹框取消按钮
    dialogCancel() {
      this.timer = setInterval(() => {
        this.time++;
      }, 1000);
      this.initData();
    },
    // 转换秒数
    getHMS(value) {
      var secondTime = parseInt(value); // 秒
      var minuteTime = 0; // 分
      var hourTime = 0; // 小时
      if (secondTime >= 60) {
        minuteTime = parseInt(secondTime / 60);
        secondTime = parseInt(secondTime % 60);
        if (minuteTime >= 60) {
          hourTime = parseInt(minuteTime / 60);
          minuteTime = parseInt(minuteTime % 60);
        }
      }
      var result = "" + (parseInt(secondTime) < 10 ? "0" + parseInt(secondTime) : parseInt(secondTime)) + "秒";
      var result2 = "" + (parseInt(minuteTime) < 10 ? "0" + parseInt(minuteTime) : parseInt(minuteTime)) + "分" + result;
      var result3 = "" + (parseInt(hourTime) < 10 ? "0" + parseInt(hourTime) : parseInt(hourTime)) + "时" + result2;
      if (parseInt(hourTime) == 0) {
        return result2;
      } else {
        return result3;
      }
    },
  },
  mounted() {
    var recOpen = function () {//一般在显示出录音按钮或相关的录音界面时进行此方法调用，后面用户点击开始录音时就能畅通无阻了
      rec.open(function () {
        console.log("已打开录音，可以点击开始了");
        //rec.start() 此处可以立即开始录音，但不建议这样编写，因为open是一个延迟漫长的操作，通过两次用户操作来分别调用open和start是推荐的最佳流程
      }, function (msg, isUserNotAllow) {//用户拒绝未授权或不支持
        // clearTimeout(t);
        console.log((isUserNotAllow ? "UserNotAllow，" : "") + "无法录音:" + msg, 1);
      });
    };
    recOpen();

    this.learningProjectName = this.cookies.kkGet("learningProjectName");
    this.studentName = this.cookies.kkGet("stuName");
    this.jobTaskLogID = this.$route.query.jobTaskLogID;

    this.teacherID = Cookies.get('teacherID');

   

    this.timer = setInterval(() => {
      this.time++;
    }, 1000);
    this.initData();
  },
  destroyed() {
    this.time = 0;
    clearInterval(this.timer);
  }
}
</script>

<style>
.evaluateContent {
  width: 100%;
  position: relative;
}

.evaluateContent .header {
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  color: #101010;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  background: #f8f8f8;
  z-index: 999;
}

.evaluateContent .header i {
  margin: 0 18px;
  font-size: 20px;
  display: block;
  width: 30px;
}

.evaluateContent .boxMain {
  margin-top: 44px;
}

/* 头部当前得分样式 */
.evaluateContent .boxMain .mainHeader {
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  background: #eafbf8;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 44px;
  left: 0;
  z-index: 999;
}

.evaluateContent .boxMain .mainHeader .headerRight {
  display: flex;
}

.evaluateContent .boxMain .mainHeader .headerRight .timeTitle {
  margin: 0 5px 0 10px;
}

.mainVideo {
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  top: 40px;
  text-align: center;
  position: fixed;
  background: white;
  z-index: 900;
}

.dianping {
  position: relative;
  left: 60%;
  width: 75px;
}

.operate {
  width: 30px;
  margin-left: 5px;
}

.start {
  background-color: dodgerblue;
  color: white;
  border: 0;
  width: 96px;
  height: 34px;
  text-align: center;
}

.stop {
  background-color: orange;
  color: white;
  border: 0;
  width: 96px;
  height: 34px;
  text-align: center;
}

/* 中间内容样式 */
.evaluateContent .mainContent {
  padding: 0 20px 20px;
  min-height: calc(100vh - 40px - 44px);
  margin-top: 77%;
}

.evaluateContent .sortList {
  margin-top: 20px;
}

.evaluateContent .sortList li {
  margin-top: 10px;
}

.evaluateContent .mainContent .sortList li .van-cell {
  padding: 0;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.evaluateContent .mainContent .sortList li .van-field__control {
  padding: 10px;
}

.evaluateContent .van-dialog__confirm {
  color: #3894ff;
}

.evaluateContent .mainContent .sortList li .quesMain {
  padding: 20px;
  border: 1px dashed #ccc;
  margin-top: 10px;
}

.evaluateContent .mainContent .sortList li .quesMain .scoreBox {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.evaluateContent .mainContent .sortList li .quesMain .scoreBox .van-slider {
  width: 55%;
  margin-top: 10px;
}

.evaluateContent .sortList li .quesMain .scoreBox .van-slider__button {
  width: 16px;
  height: 16px;
}

.evaluateContent .completeBtn {
  width: 80%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #3294ff;
  margin: 20px 10% 0;
  color: #fff;
  letter-spacing: 3px;
}

.evaluateContent .dialogMain {
  width: 100%;
  text-align: center;
  padding: 20px 0;
}

.evaluateContent .dialogMain p {
  margin-top: 8px;
}

.evaluateContent .dialogMain .btndiv {
  margin-top: 15px;
}

.evaluateContent .van-dialog__header {
  font-size: 16px;
  font-weight: bold;
}
</style>